<template>
  <v-main>
    <div class="text-center">
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">メール入力して、メーラーからパスワード再設定する。uplink外の機能。</p>
      </v-col>
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">パスワード再設定</v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      init: this.storageGet('*'),
    };
  },
  mounted () {
  },
  methods: {
    async callApi() {
      try {
        // TODO callbackでURLを呼んでもらう
        await this.$router.push({name: 'login'});
      } catch(e) {
        console.log(e);
        this.callDialog('エラー', e.message, 3);
      }
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
